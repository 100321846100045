$color: white;
// Colors
$bg-color: hsl(256,33,10);
$dot-color: hsl(256,33,70);

// Dimensions
$dot-size: 1px;
$dot-space: 22px;

.main-container {
  display: flex;
  justify-content: center;
  background: url("../../assets/lake.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;

  .main-content {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: $color;

    h1 {
      display: flex;
      margin-bottom: 0;
    }

    .bar {
      margin-top: 25px;
      margin-bottom: 25px;
      width: 100%;
      border-bottom: 1px solid $color;
    }

    span {
      font-weight: 500;
    }
  }

  .mobile-logo {
    display: none;
  }
}

@media all and (max-width: 900px) {
  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    //background: url("../../assets/flare.jpg") no-repeat center center fixed;
    //height: 100%;
    //overflow: hidden;
    background:
            linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
            linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
            $dot-color;
    background-size: $dot-space $dot-space;

    .main-content {
      padding-top: 20%!important;

      h1 {
        padding-bottom: 20pxgit ;
      }
    }

    .mobile-logo {
      display: flex!important;
      width: 200px;
    }
  }
}

