$blue-color: #106ba3;

.footer-container {
  background-color: #fafafa;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;

  .footer-sections {
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      text-align: left;
      flex-direction: column;
      flex-grow: 1;
      padding-left: 150px;

      .logo {
        width: 250px;
        margin-left: -5px;
      }

      .footer-header {
        width: fit-content;
        padding-bottom: 50px;
        border-bottom: 1px solid #ebeaeb;
      }

      .block {
        display: flex;
        flex-direction: column;
      }

      h2 {
        color: $blue-color;
      }
    }

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      img {
        border-radius: 50%;
        max-width: 250px;
        max-height: 250px;
      }

      .content {
        margin-left: 50px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        span {
          text-align: left;
          color: rgba(0, 0, 0, .65);
        }
      }
    }
  }
}

@media all and (max-width: 1050px) {
  .footer-container {
    padding-top: 10%;
    display: flex;
    flex-direction: column;

    .footer-sections {
      margin-top: 50px;
      display: flex;
      flex-direction: column;

      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 10%;
        padding-right: 10%;
        text-align: center;
      }

      .right {
        padding-top: 50px;
        display: flex;
        flex-direction: column;

        .content {
          margin-left: 0px;
        }
      }
    }
  }
}