.display-panel {
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5%;
  min-height: 90vh;

  .content-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    padding-right: 10px;
    padding-left: 10px;

    .content-heading {

    }

    .content-text {

    }
  }

  .img-side {
    width: 55%;
    img {
      max-width: 100%;
    }
  }
}

.center-panel {
  padding-bottom: 5%;
  padding-top: 5%;
  min-height: 350px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    width: 450px;
    padding-bottom: 5%;
  }

  .subheader {
    font-size: 2.5em;
    margin-bottom: 2.5em;
    text-align: center;

    .brochure {
      text-decoration: none;
      color: #1890ff;
      outline: none;
      cursor: pointer;
      transition: color .3s;

      &:hover {
        color: #40a9ff;
      }
    }
  }

  .card-row {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .twj-card {
      padding-right: 45px;
      padding-left: 45px;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      max-width: 28%;

      img {
        border-radius: 50%;
        width: 250px;
        height: 250px;
        max-width: 100%;
      }

      div {
        font-weight: 300;
        color: rgba(0, 0, 0, .65);
      }
    }
  }
}

.cert {
  img {
    width: 300px!important;
    height: 300px!important;
  }
}

@media all and (max-width: 1050px) {
  .center-panel {
    display: flex;
    justify-content: center;

    h2 {
      text-align: center;
    }

    .card-row {
      display: flex;
      flex-direction: column;
      align-items: center;

      .twj-card {
        width: 100%!important;
        max-width: 100%!important;
        padding-bottom: 75px;

        text-align: center;
      }
    }
  }
}

@media all and (max-width: 900px) {
  .display-panel {
    display: none;
  }

  .display-panel.left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-height: 50vh;

    .img-side {
      width: 100%;
    }

    .content-side {
      width: 100%;
    }
  }
}
