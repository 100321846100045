.panel-container {
  padding-left: 10%;
  padding-right: 10%;
}

.main-logo {
  position: absolute;
  height: 150px;
  top: 0;
  left: 25px;
}

@media all and (max-width: 900px) {
  .main-logo {
    display: none;
    position: absolute;
    height: 125px;
    top: 0;
    left: 25%;
  }
}
